import { DateRangeFilterBody } from '../../../commonComponents/types/dateRangeSelector';

import { Link } from './sankey';

export const FETCH_TOP_STRATEGY_SCENARIO = 'FETCH_TOP_STRATEGY_SCENARIO';
export const SET_SCENARIO_CANVAS_DATA = 'SET_SCENARIO_CANVAS_DATA';
export const SET_SCENARIO_CANVAS_LOADING = 'SET_SCENARIO_CANVAS_LOADING';
export const apiPrefix = '/api/v2/scenarios';
export const SET_SCENARIO_STRATEGY_MAP = 'SET_SCENARIO_STRATEGY_MAP';
export const SCENARIO_DETAILS_BY_ID = 'SCENARIO_DETAILS_BY_ID';
export const UPDATE_SCENARIO_CANVAS_LIST = 'UPDATE_SCENARIO_CANVAS_LIST';
export const SET_SCENARIO_LIST_COUNT = 'SET_SCENARIO_LIST_COUNT';

export interface Aspect {
  id: string;
  notes?: ScenarioNote[];
}

export interface ScenarioGenerationDetails {
  is_ai_generated: boolean;
  is_user_edited: boolean;
}

interface ScenarioCommonProps extends ScenarioGenerationDetails {
  id: string;
  title: string;
  score: number;
  delta?: number;
  dimension_score: number;
  tags: string[];
  company_tags: string[];
  dimension: string;
  anchored_notes: string[];
  description?: string;
}

export interface ScenarioApiResponse extends ScenarioCommonProps {
  aspects: Aspect[];
  notes: ScenarioNote[];
  links: Link[];
  seed_notes?: string[];
}

export interface Scenario extends Omit<ScenarioApiResponse, 'notes'> {
  viewAllConnections?: boolean;
  index?: number;
  labelDetailHeading: string;
  labelDetailScore: number;
  nodes: Record<string, ScenarioNote[]>;
}

export interface TopStrategyRequestBody extends DateRangeFilterBody {
  projectId: string;
  pageSize: number;
  pageNumber: number;
  tags?: string;
}

export interface TopStrategyCountReqBody {
  projectId: string;
  tags?: string;
}

export interface ScenarioState {
  isFetching: boolean;
  strategyList: any;
  scenarioCanvasList: { loading: boolean; canvasData: Aspect[] };
  scenarioStrategyMapList: any;
  scenarioFilterTags: {
    projectId: string;
    tags?: string[];
  }[];
  scenarioDetailsById?: any;
  scenarioListCount: number;
}

export interface CanvasSelectedNote {
  noteId: string;
  aspectId: string;
  isAnchor: boolean;
}

export interface CanvasSankeyRequestBody {
  projectId: string;
  aspect_ids: string[];
  note_ids: string[];
  anchored_note_ids: string[];
  company_tags?: string[];
}

export interface CanvasSankeyResponse {
  isSuccess: boolean;
  status: number;
  aspects: Aspect[];
  dimension: string;
  links: Link[];
  notes: any[];
  score: number;
  tags?: any[];
  title: string;
}

interface CanvasList {
  projectId: string;
  response: StrategyScenarioCanvasResponse;
}

export interface StrategyScenarioCanvasResponse {
  isSuccess: boolean;
  status: number;
  aspects: Aspect[];
}

export interface PinStrategyScenarioRequestBody {
  projectId: string;
  aspect_ids: string[];
  note_ids: string[];
  title: string;
  tags?: string[];
  dimension?: string;
  anchored_note_ids?: string[];
}

export interface PinStrategyScenarioResponse {
  isSuccess: boolean;
  status: number;
  scenario: string;
}

export interface TopStrategyScenarioResponse {
  isSuccess: boolean;
  status: number;
  scenarios: any[];
}

export interface TopStrategyScenarioCountResponse {
  isSuccess: boolean;
  status: number;
  scenarios_count: number;
}

export interface UnpinScenarioRequestBody {
  projectId: string;
  scenario_id: string;
}

export interface UpdateScenarioRequestBody {
  projectId: string;
  scenario_id: string;
  aspect_ids?: string[];
  note_ids?: string[];
  title?: string;
  tags?: string[];
  dimension?: string;
  anchored_note_ids?: string[];
}
export interface GetStrategyMapRequestBody extends DateRangeFilterBody {
  projectId: string;
  x_axis_id: string;
  y_axis_id: string;
  tags?: string;
}
export interface StrategyScenarioByIdRequestBody extends DateRangeFilterBody {
  projectId: string;
  scenario_id: string;
}
export interface ScenarioLineMekkoChartReqBody extends DateRangeFilterBody {
  projectId: string;
  top_n?: number;
  scenario_ids?: string;
}
export interface ScenarioChartProps {
  scenarioCount: number;
  dateRangeFilter: DateRangeFilterBody;
}
export interface AspectCategory {
  aspects_category:
    | ''
    | 'product_oriented'
    | 'market_oriented'
    | 'less_talkative'
    | 'bottom_of_list'
    | 'middle_of_list';
}

export interface ScenarioMetricsSort {
  funding_low?: number;
  funding_high?: number;
  revenue_low?: number;
  revenue_high?: number;
  number_of_employees_low?: number;
  number_of_employees_high?: number;
}

export interface StrategyScenarioAllSignalsReqBody
  extends ScenarioCompaniesCountBody,
    DateRangeFilterBody,
    AspectCategory {
  page_number?: number;
  page_size?: number;
  sort_by?: string;
  ascending?: boolean;
}

export interface ScenarioInsightsReqBody extends DateRangeFilterBody, AspectCategory {
  scenario_id: string;
  company_ids?: string[];
  company_tags?: string[];
}

export interface ScenarioCompaniesCountBody extends DateRangeFilterBody, AspectCategory, ScenarioMetricsSort {
  projectId: string;
  scenario_id: string;
  company_tags?: string[];
  company_ids?: string[];
}

export interface ScenarioCompaniesBody extends ScenarioCompaniesCountBody {
  page_number: number;
  page_size: number;
}

export interface AspectCategoryOptions {
  label: string;
  value: AspectCategory['aspects_category'];
}

export interface ScenarioNote {
  id: string;
  content: string;
  label: string;
  score: number;
  isSelected: boolean;
  isAnchor: boolean;
  tags?: string[];
  aspectId: string;
  size: number;
  is_missing: boolean;
  is_anchored_note: boolean;
  is_seed_note: boolean;
}

export interface UnpinScenarioSignalCountReqBody extends DateRangeFilterBody, AspectCategory {
  projectId: string;
  notes: string[];
  anchored_notes: string[];
  company_tags?: string[];
}

export interface UnpinScenarioSignalReqBody extends UnpinScenarioSignalCountReqBody {
  page_number: number;
  page_size: number;
  sort_by: string;
  ascending: boolean;
}

export interface UnpinScenarioCompaniesReqBody extends UnpinScenarioSignalCountReqBody {
  page_number: number;
  page_size: number;
}

export interface RecommendedScenarioNotes {
  projectId: string;
  selected_aspect: string;
  selected_notes: string[];
  aspect_left: string;
  notes_left: string[];
  aspect_right: string;
  notes_right: string[];
  company_tags: string[];
}

export interface SimpleScenariosCountReqBody {
  projectId: string;
  tags?: string;
}

export interface SimpleStrategyScenariosReqBody extends SimpleScenariosCountReqBody {
  pageSize: number;
  pageNumber: number;
}

export interface SimpleScenariosByIdReqBody {
  projectId: string;
  scenario_id: string;
}

export interface SimpleScenarioApiResponse extends ScenarioCommonProps {
  aspects: string[];
  notes: string[];
  rank: number;
}

export interface InsightsSignalsReqBody {
  projectId: string;
  signal_ids: string[];
}

export interface ScenarioCompany {
  tld?: string;
  name?: string;
  domain?: string;
  strength?: string;
  company_id?: string;
  description?: string;
  strongest_signal?: any;
}

export interface ScenarioCompaniesApiResponse {
  overall_strength: number;
  selection_strength: number;
  signals: ScenarioCompany[];
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
}
